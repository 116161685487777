<template>
  <div :class="bem()" class="relative h-12" v-if="show">
    <select class="" v-model="selected" @change="selectRefinement()">
      <option disabled selected value><slot name="header"></slot></option>
      <option
        v-for="facet in facetValues"
        :value="facet.name"
        :key="facet.name"
        :class="facet.isRefined ? bem('item', 'active') : bem('item')"
      >
        <span :class="bem('value')" class="text-black">{{ facet.name }}</span>
      </option>
    </select>

    <div
      class="
        pointer-events-none
        absolute
        pin-y
        right-0
        flex
        items-center
        px-2
        text-grey-darker
      "
    >
      <svg
        class="fill-current h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
    </div>

    <slot name="footer"></slot>
  </div>
</template>

<script>
import { Component } from "vue-instantsearch";
import { FACET_OR, FACET_AND } from "vue-instantsearch";

export default {
  mixins: [Component],
  props: {
    attributeName: {
      type: String,
      required: true,
    },
    operator: {
      type: String,
      default: FACET_OR,
      validator(rawValue) {
        const value = rawValue.toLowerCase();
        return value === FACET_OR || value === FACET_AND;
      },
    },
    limit: {
      type: Number,
      default: 10,
    },
    sortBy: {
      default() {
        return ["isRefined:desc", "count:desc", "name:asc"];
      },
    },
  },
  data() {
    return {
      blockClassName: "ais-refinement-list",
      selected: "",
    };
  },
  created() {
    this.searchStore.addFacet(this.attributeName, this.operator);
  },
  destroyed() {
    this.searchStore.stop();
    this.searchStore.removeFacet(this.attributeName);
    this.searchStore.start();
  },
  computed: {
    facetValues() {
      let x = this.searchStore.getFacetValues(
        this.attributeName,
        this.sortBy,
        this.limit
      );

      for (let k in x) {
        if (x[k].name === "") {
          delete x[k];
        }
      }

      return x;
    },
    show() {
      return this.facetValues.length > 0;
    },
  },
  methods: {
    selectRefinement() {
      this.searchStore.clearRefinements(this.attributeName);

      return this.searchStore.addFacetRefinement(
        this.attributeName,
        this.selected
      );
    },
  },
  watch: {
    operator() {
      this.searchStore.addFacet(this.attributeName, this.operator);
    },
  },
};
</script>

<style scoped>
.ais-refinement-list__value {
  color: #000;
}
select {
  border-radius: 0.3rem;
  min-height: 48px;
  width: 100%;
}
</style>