<template>
    <button class="flex-1 text-center bg-black text-white p-3 no-underline rounded"
            type="button"
            @click="submitForm">
        {{ buttonText }}
    </button>
</template>

<script>
    export default {
        prop: [
            'text'
        ],
        data() {
            return {
                saving: false,
                buttonText: this.text || 'Save'
            };
        },
        methods: {
            submitForm() {
                if (!this.saving) {
                    this.saving = true;
                    document.getElementById('primary-form').submit();
                }
            }
        }
    }
</script>
