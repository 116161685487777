<template>
  <ul class="quick-read-toggle" :class="{slide: mode === 'full' }">
    <li
      class="quick-read-toggle-option one"
      :class="{active: mode === 'quick'}"
      @click="setMode('quick')"
    >
      Quick
      Read
    </li>
    <li
      class="quick-read-toggle-option two"
      :class="{active: mode === 'full'}"
      @click="setMode('full')"
    >Full Read</li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      mode: "quick" // or 'full'
    };
  },
  methods: {
    setMode(val) {
      let full = $("#full");
      let quick = $("#quick");
      let citationsHeading = $("#citations-heading");
      let citationsBody = $("#citations-body");

      // Jquery workaround eventBus.
      // Transition.
      if (val === "quick") {
        this.hide(full);
        this.hide(citationsHeading);
        this.hide(citationsBody);
        this.show(quick);
      } else {
        this.hide(quick);
        this.show(full);
        this.show(citationsHeading);
        this.show(citationsBody);
      }

      this.mode = val;
    },

    show(el) {
      // El is jquery instance.
      el.show();
      el.css("transition", "opacity 0.4s ease-in-out");
      el.css("opacity", 1);
    },

    hide(el) {
      el.css("transition", "opacity 0.2s ease-out-in");
      el.css("opacity", 0);
      el.hide();
    }
  },
  mounted() {
    this.setMode("quick");
  }
};
</script>

<style>
.quick-read-toggle {
  display: inline-block;
  padding: 0;
  list-style: none;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: #e8eaed;
  user-select: none;
}

.quick-read-toggle:after {
  content: "";
  width: 50%;
  background: #5c3e96;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 200ms ease-in-out;
  border-radius: 10px;
}

.quick-read-toggle.slide:after {
  left: 50%;
}

.quick-read-toggle-option {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  color: #5c3e96;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 28px;
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-block;
  transition: color 250ms ease-in-out;
}

.quick-read-toggle-option.active {
  color: #fff;
}
</style>