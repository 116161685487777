<template>
  <div :class="bem()" v-if="show">

    <slot name="header"></slot>

    <slot v-for="(result, index) in results"
          :index="index"
          :result="translatable(result)"
          :class="{'fade-in': true}"
          :lang="lang">   
    </slot>

    <slot name="footer"></slot>
    

  </div>
</template>

<script>

import { Component } from 'vue-instantsearch';

export default {
  mixins: [Component],
  props: {
    stack: {
      type: Boolean,
      default: true,
    },
    resultsPerPage: {
      type: Number,
      default: 12
    },
    lazyLoad: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      lang: document.documentElement.lang,
      blockClassName: 'ais-results',
      haveResults: false,
      loadingMoreNearBottom: false
    };
  },
  created() {
    this.updateResultsPerPage();

    // if no lazy
    if(this.lazyLoad){
      window.addEventListener('scroll', this.loadMoreNearBottom);
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.loadMoreNearBottom)
  },

  watch: {
    resultsPerPage() {
      this.updateResultsPerPage();
    },

    'searchStore.results'(val) {
      this.haveResults = val.length > 0
    }
  },
  methods: {
    translatable(result) {
        let ret = {}

        for(let key in result) {
            if (result[key] && result[key][this.lang]) {
                ret[key] = result[key][this.lang]
            } else if (result[key] && result[key]['en']) {
                ret[key] = result[key]['en']
            } else {
                ret[key] = result[key]
            }
        }

        return ret
    },

    // check for verison in selected lang, else default to the english verison. Deprecated
    translate: function (value) {
      let lang = this.lang;
      if (lang in value){
        return value[lang];
      } else {
        return value['en'];
      }
   },
    updateResultsPerPage() {
      if (typeof this.resultsPerPage === 'number' && this.resultsPerPage > 0) {
        this.searchStore.resultsPerPage = this.resultsPerPage;
      }
    },

    loadMoreNearBottom() {
      let body = document.body;
      let html = document.documentElement;

      let currentPosition = window.scrollY;

      let height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);

      if (currentPosition >= height - 2000 && this.haveResults && !this.loadingMoreNearBottom) {
        this.loadingMoreNearBottom = true;
        this.searchStore.page++;
        setTimeout(() => {
          this.loadingMoreNearBottom = false;
          this.searchStore.page++
        }, 1000)
      }
    },
  },
  computed: {
    results() {
      if (this.stack === false) {
        return this.searchStore.results;
      }

      if (typeof this.stackedResults === 'undefined') {
        this.stackedResults = [];
      }

      if (this.searchStore.page === 1) {
        this.stackedResults = [];
      }

      if (
        this.stackedResults.length === 0 ||
        this.searchStore.results.length === 0
      ) {
        this.stackedResults.push(...this.searchStore.results);
      } else {
        const lastStacked = this.stackedResults[this.stackedResults.length - 1];
        const lastResult = this.searchStore.results[
          this.searchStore.results.length - 1
        ];

        if (lastStacked.objectID !== lastResult.objectID) {
          this.stackedResults.push(...this.searchStore.results);
        }
      }

      return this.stackedResults;
    },
    show() {
      return this.results.length > 0;
    },
  },
};
</script>
<style scoped>

.fade-in {
    opacity: 0;

    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: .5s;
    -moz-animation-duration: .5s;
    animation-duration: .5s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>