<template>
  <div v-on-clickaway="closeDropdown" class="">
    <div class="flex w-full">
      <div class="relative w-full">
        <button
          @click="showDropdown = !showDropdown"
          class="
            bg-smoke
            w-full
            outline-none
            text-white
            px-2
            pt-2
            pb-1
            rounded
            lg:rounded-t-none
            flex
          "
        >
          <div
            class=""
            v-for="(item, key) in datatype"
            :key="key"
            v-if="key === selected"
          >
            <span class="font-bold uppercase">{{ key }}</span>
            <!-- {{item.label}} -->
          </div>

          <!--  <div class="dropdown-arrow pointer-events-none absolute pin-y right-0 flex items-center px-2 text-white h-12">

                        <svg fill="white" class="-mt-4 h-4 w-4 pr-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                        </svg>

                    </div> -->
        </button>

        <div
          v-if="showDropdown"
          class="
            p-4
            pb-2
            appearance-none
            dropdown-bg-color
            rounded
            shadow-md
            absolute
            top-0
            left-0
            lg:pin-none
            lg:right-0
            min-w-full
          "
          style="z-index: 1050; min-width: 130px"
        >
          <ul class="list-none no-underline">
            <li
              class="mb-1 cursor-pointer hover:text-primary text-black flex items-center"
              v-for="(item, key) in datatype"
              :key="key"
              @click="selectOption(item.route, key)"
            >
              <span
                class=""
                :style="{ visibility: key === selected ? 'visible' : 'hidden' }"
              >
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M0 12.116l2.053-1.897c2.401 1.162 3.924 2.045 6.622 3.969 5.073-5.757 8.426-8.678 14.657-12.555l.668 1.536c-5.139 4.484-8.902 9.479-14.321 19.198-3.343-3.936-5.574-6.446-9.679-10.251z"
                  />
                </svg>
              </span>
              <span class="text-white pl-2">{{ item.label }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--appearance-none bg-black-40 block border-2  lg:border-0 border-grey font-bold py-4 lg:py-2 px-4 pr-8 rounded lg:rounded-t-none shadow-lg text-white w-full-->
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  mixins: [clickaway],
  props: ["datatype", "selected"],
  data() {
    return {
      showDropdown: false,
      showDeleteModal: false,
    };
  },
  methods: {
    selectOption(route, key) {
      if (key === this.selected) {
        this.closeDropdown();
        console.log("close");
      } else {
        location = route;
      }
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<style scoped>
.dropdown-bg-color {
  background-color: #000;
  /*background: rgba(255,255,255,0.3);*/
}
</style>