<template>
    <div>
        <img :height="size" :src="link" alt="" class="pattern" :style="{ top: yPosition, left: '-'+xPosition }">
        <img :height="size" :src="link2" alt="" class="pattern" :style="{ top: yPosition2, right: '-'+xPosition }">
    </div>
</template>

<script>
// Random number function is Math.floor(Math.random()*(max-min+1)+min)

export default {
    data() {
        return {
            // This should be made DRY
            link: '/images/spot-patterns/spot-'+ Math.floor(Math.random()*(5-1+1)+1) +'.svg',
            link2: '/images/spot-patterns/spot-'+ Math.floor(Math.random()*(5-1+1)+1) +'.svg',
            yPosition: Math.floor(Math.random()*(75-25+1)+25) + '%',
            yPosition2: Math.floor(Math.random()*(75-25+1)+25) + '%',
            xPosition: Math.floor(Math.random()*(120-100+1)+100) + 'px',
            size: Math.floor(Math.random()*(256-128+1)+128)
        }
    },
}
</script>

<style>
    .pattern {
        opacity: .4;
        position: relative;
    }
</style>