<template>
  <div class="w-full md:h-screen md:border-r border-grey-light">
    <div class="flex flex-wrap justify-between mb-4">
      <div class="ml-4 mt-4">
        <a class="text-black no-underline text-lg font-black" href="/admin"
          >Bi.org</a
        >
      </div>
      <div class="visible md:hidden pt-6 px-4 md:p-0">
        <button
          v-if="MenuHidden == true"
          v-on:click="openMenu"
          class="font-black text-black"
        >
          <i class="fas fa-bars"></i> Menu
        </button>
        <button
          v-if="MenuHidden == false"
          v-on:click="openMenu"
          class="text-black no-underline no-underline font-black"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <div :class="{ hidden: MenuHidden }" class="md:block">
      <div class="items-baseline py-2 text-center">
        <!--        <a :class="{ 'active': activePage('dashboard') }" class="flex md:flex-col lg:flex-row items-center md:items-start lg:items-center justify-start nav-link-admin" href="/admin">
                    <i class="text-lg fa-fw fas fa-tachometer-alt mr-2"></i>
                    <span class="text-sm block">Dashboard</span>
                </a> -->

        <a
          :class="{ active: activePage('articles') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/articles"
        >
          <i class="text-lg fa-fw far fa-newspaper mr-2"></i>
          <span class="text-sm block">Articles</span>
        </a>

        <a
          :class="{ active: activePage('pages') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/pages"
        >
          <i class="text-lg fa-fw fa fa-file mr-2"></i>
          <span class="text-sm block">Pages</span>
        </a>

        <a
          :class="{ active: activePage('additional-resources') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/additional-resources"
        >
          <i class="text-lg fa-fw fas fa-briefcase mr-2"></i>
          <span class="text-sm block">Resources</span>
        </a>

        <a
          :class="{ active: activePage('glossary') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/glossary"
        >
          <i class="text-lg fa-fw fas fa-book mr-2"></i>
          <span class="text-sm block">Glossary</span>
        </a>

        <a
          :class="{ active: activePage('Profiles') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/profiles"
        >
          <i class="text-lg fa-fw fa fa-users mr-2"></i>
          <span class="text-sm block">Profiles</span>
        </a>

        <a
          :class="{ active: activePage('famous-bi-people') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/famous-bi-people"
        >
          <i class="text-lg fa-fw fa fa-star mr-2"></i>
          <span class="text-sm block">Famous Bi</span>
        </a>

        <a
          :class="{ active: activePage('bi-characters') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/bi-characters"
        >
          <i class="text-lg fa-fw fab fa-rebel mr-2"></i>
          <span class="text-sm block">Bi Characters</span>
        </a>

        <a
          :class="{ active: activePage('bi-media') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/bi-media"
        >
          <i class="text-lg fa-fw fas fa-tv mr-2"></i>
          <span class="text-sm block">Bi Media</span>
        </a>

        <a
          :class="{ active: activePage('questions') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/questions"
        >
          <i class="text-lg fa-fw fa fa-question-circle mr-2"></i>
          <span class="text-sm block">Questions</span>
        </a>

        <a
          :class="{ active: activePage('bi-101s') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/bi-101s"
        >
          <i class="text-lg fa-fw fa fa-graduation-cap mr-2"></i>
          <span class="text-sm block">Bi 101</span>
        </a>

        <a
          :class="{ active: activePage('contact-requests') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/contact-requests"
        >
          <i class="text-lg fa-fw fa fa-exclamation-circle mr-2"></i>
          <span class="text-sm block">Contacts</span>
        </a>

        <a
          :class="{ active: activePage('settings') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/settings/headers"
        >
          <i class="text-lg fa-fw fas fa-cog mr-2"></i>
          <span class="text-sm block">Settings</span>
        </a>

        <a
          :class="{ active: activePage('homeslides') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/homeslides"
        >
          <i class="text-lg fa-fw fas fa-home mr-2"></i>
          <span class="text-sm block">Home Page</span>
        </a>

        <a
          :class="{ active: activePage('users') }"
          class="
            flex
            md:flex-col
            lg:flex-row
            items-center
            md:items-start
            lg:items-center
            justify-start
            nav-link-admin
          "
          href="/admin/users"
        >
          <i class="text-lg fa-fw fa fa-users mr-2"></i>
          <span class="text-sm block">Users</span>
        </a>

        <a
          class="
            bg-gray-100
            hover:bg-purple
            hover:text-white
            no-underline
            mt-4
            rounded
            inline-block
            px-4
            py-4
            text-black
          "
          href="/"
        >
          <!-- <i class="text-lg fa-fw fas fa-cog mr-2"></i> -->
          <span class="text-sm block">View Live Site</span>
        </a>

        <a
          class="
            bg-gray-100
            hover:bg-purple
            hover:text-white
            no-underline
            mt-4
            rounded
            inline-block
            px-4
            py-4
            text-black
          "
          href="/logout"
        >
          <!-- <i class="text-lg fa-fw fas fa-cog mr-2"></i> -->
          <span class="text-sm block">Logout</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
var ourLocation = document.URL.split("/")[3];
console.log(ourLocation);

export default {
  data() {
    return {
      MenuHidden: true,
    };
  },
  mounted() {},
  methods: {
    openMenu: function(event) {
      this.MenuHidden = !this.MenuHidden;
    },
    activePage(pageTab) {
      // if translated page and non translated page
      if (
        pageTab == document.URL.split("/")[5] ||
        pageTab == document.URL.split("/")[4]
      ) {
        return true;
      }
      // if the dashboard page
      // else if(document.URL.split('/')[3] == 'admin' &&  !window[document.URL.split('/')[4]] == undefined){
      //      return true;
      // }
    },
  },
};
</script>
