<template>
    <header class="">
        <div class="p-4 ">
            <div class="flex mx-auto items-center justify-between">
               
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data() {
        return {
            menu: false
        }
    },
    mounted() {},
    methods: {
        openMenu: function(event) {
            this.menu = true;
        },
        closeMenu: function(event) {
            this.menu = false;
        }
    }
}
</script>

<style scoped>
</style>