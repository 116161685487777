<template>
    <div :class="bem()" v-if="show">
        <slot name="header"></slot>

        <slot v-for="result in results" :result="result">
            Result 'objectID': {{ result.objectID }}
        </slot>

        <slot name="footer"></slot>

    </div>
</template>

<script>
import { Component } from 'vue-instantsearch';
export default {
    mixins: [Component],
    props: {
        stack: {
            type: Boolean,
            default: false,
        },
        resultsPerPage: {
            type: Number,
        },
    },
    data() {
        return {
            lang: document.documentElement.lang,
            blockClassName: 'ais-results',
        };
    },
    created() {
        console.log(this.lang);
        this.updateResultsPerPage();
    },
    watch: {
        resultsPerPage() {
            this.updateResultsPerPage();
        },
    },
    methods: {
        // check for verison in selected lang, else default to the english verison
        translate: function(value) {
            var lang = this.lang;
            if (lang in value) {
                return value[lang];
            } else {
                return value['en'];
            }
        },
        // make a random size for the circle
        randomStyle: function() {
            // returns a number between 40 and 200
            var randomSize = Math.floor(Math.random() * 200) + 40;
            var left = Math.floor(Math.random() * 800) + 10;
            var top = Math.floor(Math.random() * 800) + 80;
            var animationDuration = Math.floor(Math.random() * 20) + 80;
            return {
                width: randomSize + 'px',
                height: randomSize + 'px',
                left: left + 'px',
                top: top + 'px',
                animationDuration: animationDuration + 's'
            };
        },
        updateResultsPerPage() {
            if (typeof this.resultsPerPage === 'number' && this.resultsPerPage > 0) {
                this.searchStore.resultsPerPage = this.resultsPerPage;
            }
        },
    },
    computed: {
        results() {
            if (this.stack === false) {
                return this.searchStore.results;
            }
            if (typeof this.stackedResults === 'undefined') {
                this.stackedResults = [];
            }
            if (this.searchStore.page === 1) {
                this.stackedResults = [];
            }
            if (
                this.stackedResults.length === 0 ||
                this.searchStore.results.length === 0
            ) {
                this.stackedResults.push(...this.searchStore.results);
            } else {
                const lastStacked = this.stackedResults[this.stackedResults.length - 1];
                const lastResult = this.searchStore.results[
                    this.searchStore.results.length - 1
                ];
                if (lastStacked.objectID !== lastResult.objectID) {
                    this.stackedResults.push(...this.searchStore.results);
                }
            }
            return this.stackedResults;
        },
        show() {
            return this.results.length > 0;
        },
    },
};
</script>

<style>
.user-circle {
    animation-name: myOrbit;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    border-radius: 100%;
    box-shadow: 1px 8px 46px 1px rgba(0, 0, 0, 0.36);
    overflow: hidden;
    position: absolute;
}












/* TREV, this is buggy, some circles julp away on hover. Needs review. */

.user-circle:hover {
    animation: none !important;
    height: 320px !important;
    width: 320px !important;
    z-index: 8000 !important;
}

@keyframes myOrbit {
    from {
        transform: rotate(0deg) translateX(150px) rotate(0deg);
    }
    to {
        transform: rotate(360deg) translateX(150px) rotate(-360deg);
    }
}
</style>