<template>
    <div class="flex justify-center">
        <div ref="circleGrid" class="user-circle-grid w-full">
            <a   href=""
                 v-for="user in results"
                 class="user-circle-item rounded-full shadow-lg"
                 :data-id="user.id"
                 :id="'user'+user.id"
                 :key="user.id"
                 :style="getUserCircleStyle(user)"
            >
            </a>
        </div>
    </div>
</template>
<script>
    import {Results} from 'vue-instantsearch'

    export default {
        extends: Results,
        props: {
            stack: {
                type: Boolean,
                default: true,
            },
            resultsPerPage: {
                type: Number,
                default: 2
            },
        },
        data() {
            return {
                transitionDurations: ['transitionDuration', 'msTransitionDuration', 'webkitTransitionDuration', 'mozTransitionDuration', 'oTransitionDuration'],
                transitionDurationProperty: null,

                transforms: ['transform', 'msTransform', '-webkit-transform', 'mozTransform', 'oTransform'],
                transformProperty: null,

                currentUsers: [],
                MAX_ELEMENTS: 15

            }
        },
        watch: {
            'searchStore.results'(results) {
                // If there is no more results. Pagination is at last page, reset it to zero.
                if (results.length === 0) {
                    this._searchStore.page = 0
                    return
                }
            }
        },
        methods: {
            getUserCircleStyle(user) {
                let curr = document.getElementById('user' + user.id)
                if (curr) {
                    return curr.style.cssText
                }

                let size = Math.floor(Math.random() * 120 + 60)

                let style = {
                    width: size + 'px',
                    height: size + 'px',
                    'background-image': 'url("' + user.profile_image + '")'
                }

                style[this.transformProperty] = 'translate3d(' + this.getRandomXPosition() + 'px' + ', ' + this.getRandomYPosition() + 'px' + ', 0)'

                return style

            },

            startAnimation() {
                const list = document.getElementsByClassName('user-circle-item')

                for (let i = 0; i < list.length; ++i) {
                    let id = list[i].dataset.id
                    let el = list[i]

                    if (el && this.currentUsers.indexOf(id) === -1) {
                        this.currentUsers.push(id)
                        this.setTransitionForItem(el)
                    }
                }

                if (list.length <= this.MAX_ELEMENTS) {
                    return
                }

                for (let i = 0; i < list.length - this.MAX_ELEMENTS; ++i) {
                    let el = list[i]
                    let id = list[i].dataset.id

                    el.style.animation = 'fadeout 4s'
                    el.style.webkitAnimation = 'fadeout 4s'
                    /* Safari, Chrome and Opera > 12.1 */
                    el.style.mozAnimation = 'fadeout 4s'
                    /* Firefox < 16 */
                    el.style.msAnimation = 'fadeout 4s'
                    /* Internet Explorer */
                    el.style.oAnimation = 'fadeout 4s'
                    /* Opera < 12.1  */

                    setTimeout(() => {
                        el.remove()
                    }, 3500)
                }
            },

            setTransitionForItem(el) {
                el.addEventListener('transitionend', this.updatePosition, false);
                el.addEventListener('webkitTransitionEnd', this.updatePosition, false);
                el.addEventListener('mozTransitionEnd', this.updatePosition, false);
                el.addEventListener('msTransitionEnd', this.updatePosition, false);
                el.addEventListener('oTransitionEnd', this.updatePosition, false);

                // Hover effect.
                el.addEventListener("mouseover", this.stopTransition, false);
                el.addEventListener("mouseout", this.releaseTransition, false);


                this.setTranslate3DTransform(el);
                this.setTransitionDuration(el);
            },

            stopTransition(e) {
                let el = e.currentTarget

                let computedStyle = window.getComputedStyle(el)
                let somePause = computedStyle.getPropertyValue('transform')
                el.style.transform = somePause + ' scale(1.25)'
                el.style.transitionProperty = 'none'
            },

            releaseTransition(e) {
                let el = e.currentTarget
                let t = el.style.transform

                el.style.transform = t.replace('scale(1.25)', 'scale(1)')

                setTimeout(() => {
                    el.style.transitionProperty = 'transform'
                    this.setTranslate3DTransform(el)
                }, 20)
            },


            updatePosition(e) {
                let circle = e.currentTarget;

                if (e.propertyName.indexOf('transform') >= 0) {
                    this.setTranslate3DTransform(circle);
                    this.setTransitionDuration(circle);
                }
            },

            getRandomXPosition() {
                let w = this.$refs.circleGrid.getBoundingClientRect().width
                let x = Math.round(Math.random() * w - 180)

                if (x < -20) return -20

                return x
            },

            getRandomYPosition() {
                let h = this.$refs.circleGrid.getBoundingClientRect().height
                let y = Math.round(Math.random() * h - 180)

                if (y < -20) return -20

                return y
            },

            getRandomDuration() {
                return (25 + Math.random() * 5) + 's';
            },

            getSupportedPropertyName(properties) {
                for (let i = 0; i < properties.length; i++) {
                    if (typeof document.body.style[properties[i]] !== 'undefined') {
                        return properties[i];
                    }
                }
                return null;
            },

            setTranslate3DTransform(element) {
                element.style[this.transformProperty] = 'translate3d(' + this.getRandomXPosition() + 'px' + ', ' + this.getRandomYPosition() + 'px' + ', 0) rotate(0.01deg)';
            },

            setTransitionDuration(element) {
                if (this.transitionDurationProperty) {
                    element.style[this.transitionDurationProperty] = this.getRandomDuration();
                }
            },
        },
        created() {
            this.transitionDurationProperty = this.getSupportedPropertyName(this.transitionDurations);
            this.transformProperty = this.getSupportedPropertyName(this.transforms);
        },
        mounted() {
            // Populating users
            let k = 0;
            let populating = setInterval(() => {
                // Animate previously added items.
                setTimeout(() => {
                    this.startAnimation()
                }, 1000)

                // Finish initial populating after 5 iteration.
                if (k === 5) {
                    clearInterval(populating)
                }

                this._searchStore.page++
                ++k
            }, 300)


            // Start pagination every 0.3 of minute.
            setInterval(() => {
                this._searchStore.page++

                setTimeout(() => {
                    this.startAnimation()
                }, 1000)

            }, 0.3 * 60 * 1000)

        }
    }
</script>

<style>
    .user-circle-grid {
        height: 500px;
        max-width: 800px;
        position: relative;
    }

    .user-circle-item {
        position: absolute;
        background-color: white;
        background-size: cover;
        transition-property: transform;
        transition-timing-function: ease-in-out;

        -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 4s; /* Firefox < 16 */
        -ms-animation: fadein 4s; /* Internet Explorer */
        -o-animation: fadein 4s; /* Opera < 12.1 */
        animation: fadein 4s;
    }

    .user-circle-item:hover {
        z-index: 1000;
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeout {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }


</style>