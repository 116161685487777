export default {
    data() {
        return {
            step: 1,
            steps: 3,

            user: {}
        }
    },
    props: ['model'],
    computed: {
        currentStep() {
            return this.step
        },

        isLastStep() {
            return this.step === this.steps
        },

        allowSecond() {
            if(!this.user.first_name || !this.user.last_name || !this.user.email ||
                !this.user.password || !this.user.bisexual_identity) {
                return false
            }

            return true
        },

        allowThird() {
            if(!this.user.what_means_me || !this.user.world_know_bisexuals || !this.user.path_bisexual_identity ||
                !this.user.toughest_bisexual || !this.user.best_thing_bisexual || !this.user.reacted_bisexual ||
                !this.user.advice_who_thinks || !this.allowSecond) {
                return false
            }

            return true
        },
    },
    methods: {
        next() {
            if (this.step === this.steps) {
                // submit
                this.step = 1;
            } else if( this.isNextStepAllowed(this.step + 1)) {
                this.step++
            }
        },

        gotoStep(step) {
            console.log(step)
          if(step === 1) {
              this.step = 1;
          }  else if (step === 2 && this.allowSecond) {
              this.step = 2;
          } else if (step === 3 && this.allowThird) {
              this.step = 3;
          } else {
              alert('Not allowed, fill all required data');
          }
        },

        previous() {
            this.step--;
            if (this.step < 1) {
                this.step = 1;
            }
        },

        isNextStepAllowed(step) {
            if(step === 3) {
                return this.allowThird;
            } else if (step === 2) {
                return this.allowSecond;
            }

            return false;
        },

        submit() {

            for(let k in this.user) {
                $('<input>').attr({
                    type: 'hidden',
                    name: k,
                    value: this.user[k]
                }).appendTo('#accountForm');
            }

            $('#accountForm').submit();
        }
    },
    created() {
        this.user = this.model
    }
}
