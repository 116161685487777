<template>
  <section class="w-full">
    <!-- trigger -->
    <button
      @click="triggerAccordion"
      class="flex p-4 shadow w-full justify-between pointer font-bold mt-1"
      :id="item.id"
      :aria-expanded="accordion"
    >
      <div class="text-left">{{ item.title }}</div>
      <svg
        viewBox="0 0 20 20"
        width="20"
        height="20"
        class="fill-current text-grey-dark accordion-arrow"
      >
        <title>cheveron down</title>
        <path
          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        ></path>
      </svg>
    </button>

    <!-- content -->
    <div v-if="accordion" class="bg-gray-100 p-4">
      <span v-html="item.content"></span>
      <a
        class="btn btm-sm mt-6 p-2 text-sm"
        :href="'/questions/' + this.item.slug"
      >
        <i class="fa fa-bookmark"></i>
        Share
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: ["item", "id"],
  data() {
    return {
      accordion: false,
    };
  },
  methods: {
    triggerAccordion: function(event) {
      this.accordion = !this.accordion;
    },
  },
};
</script>
