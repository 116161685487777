<template>
    <div>
        <div v-if="loading" class="save-loader">Saving...</div>
    </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    props: {
      modelId: {
        default: null
      },

      formId: {
        type: String,
        default: 'primary-form'
      },

      autoSaveUrl: {
        type: String,
        default: ''
      }

    },

    data() {
      return {
        loading: false,
        objectId: null,
        lastRedactorUpdate: null
      }
    },

    methods: {
      getFormData() {

        // Collect form fields to dictionary.
        let form = document.querySelector('#' + this.formId);
        let formData = new FormData(form);
        let ret = [];
        let skipList = ['_method'];

        for (let [key, value] of formData.entries()) {
          if (skipList.indexOf(key) >= 0) {
            continue;
          }

          // Check if key is array and parse it, eg: categories[123] = 1
          // Check value in this case will be [123]
          let check = key.match(/\[\d+\]/)
          if(check) {
            key = key.substr(0, check.index)

            // From [123] get only number: 123
            let num = check[0].slice(1, -1);
            let v = {};
            v[parseInt(num)] = value;

            if(ret[key]) {
              // If dictionary already exists, merge values.
              ret[key] = {...ret[key], ...v};
            } else {
              // Otherwise, create new dictionary.
              ret[key] = v;
            }
          } else {
            // Default, just save value to key.
            ret[key] = value;
          }
        }

        // Attach object id.
        ret.id = this.objectId;

        return ret;
      },

      save: _.debounce(function (e) {

        this.loading = true;

        let vm = this;

        // Prepare form data.
        let data = this.getFormData();
        console.log('storing ', data);

        // Save and fetch item id.
        axios.post(this.autoSaveUrl, {...data})
          .then(function (response) {
            if (response.data.data.id) {
              // Set object ID from DB.
              vm.objectId = response.data.data.id
            }

            // Status could be downgraded to draft if validation fails on backend,
            // so restore it to proper value.
            if (!response.data.validation && response.data.data.status) {
              $('#select-status').val(response.data.data.status);
            }

            console.log('Save done.')
          })
          .catch(function (error) {
            console.log(error);
            alert('Autosave endpoint error.');
          })
          .finally(() => {
            vm.loading = false;
          });

        console.log('Saving...')
      }, 2000),

      registerEventListeners() {
        let vm = this;

        $('#primary-form').on('keyup change paste', 'input, select, textarea', function () {
          vm.save();
        });
      },

    },

    created() {
      this.objectId = this.modelId;
    },

    mounted() {
      // this.registerEventListeners();
      //
      // // Subscribe on changes made by redactor which are
      // // notified through window.redactorUpdated.timestamp field.
      // this.lastRedactorUpdate = window.redactorUpdated.timestamp;
      // window.redactorUpdated.subscribers.push(this.save);
    }
  }
</script>

<style scoped>
    .save-loader {
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 10px;
        background: #000000;
        color: #FFF;
    }
</style>