<template>
    <div class="flex ml-4">
        <a v-if="!isSaving" class="btn" @click="saveOrdering">
            <i class="fa fa-save mr-2"></i> Save
        </a>
        <a v-else class="btn" @click="noop">
            <i class="fa fa-spinner mr-2"></i> Saving
        </a>
    </div>
</template>

<script>
export default {
    props: ['apiUrl', 'changeset', 'items'],
    data: function() {
        return {
            isSaving: false
        };
    },
    methods: {
        noop() {

        },
        saveOrdering() {
            let This = this;

            this.isSaving = true;
            this.notifySaving();

            let parts = [];
            for (let i = 0; i < this.changeset.length; i++) {
                for (let j = 0; j < this.items.length; j++) {
                    if (this.changeset[i] === this.items[j].id) {
                        parts.push(this.changeset[i] + '@' + (j + 1));
                        break;
                    }
                }
            }

            let data = {
                orderInfo: parts.join(',')
            };

            console.log('Saving...');

            axios.post(this.apiUrl, {...data})
                .then(function(response) {
                    console.log(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(function() {
                    This.isSaving = false;
                    This.notifySaving();
                });

            return true
        },
        notifySaving: function() {
            this.$emit('adminListingSaving', this.isSaving);
        }
    }
}
</script>