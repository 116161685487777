<template>
  <nav class="flex justify-center w-full">
    <ais-pagination
      v-if="morePages"
      :padding="1"
      v-on:page-change="onPageChange"
      :class-names="classNamesAttr"
    >
    </ais-pagination>
  </nav>
</template>

<script>
import { Component } from "vue-instantsearch";

export default {
  mixins: [Component],
  props: {
    styleName: {
      type: String,
      default: "regular",
    },
  },
  computed: {
    morePages() {
      return this.searchStore && this.searchStore.totalPages > 1;
    },

    classNamesAttr() {
      if (this.styleName === "front") {
        return {
          "ais-pagination": "flex justify-start font-black list-none my-8",
          "ais-pagination__item--active": "active-page",
          "ais-pagination__item--disabled": "disabled",
          "ais-pagination__item": "mr-2 md:mr-6",
          "ais-pagination__link":
            "bg-purple block no-underline page-link py-3 px-3 md:px-6 rounded-lg text-black",
        };
      }

      return {
        "ais-pagination": "flex justify-start font-black list-none my-8",
        "ais-pagination__item--active": "active-page",
        "ais-pagination__item--disabled": "disabled",
        "ais-pagination__item": "mr-2 md:mr-6",
        "ais-pagination__link":
          "block border-black border-2 no-underline page-link py-2 px-2 md:px-4 rounded text-black",
      };
    },
  },

  methods: {
    onPageChange(page) {
      window.scrollTo(0, 0);
    },
  },

  created() {
    console.log("pagination", this.searchStore);
  },
};
</script>