<template>
  <div :class="containerCss">
    <span
      class="text-white bg-black text-center w-1/12 font-bold py-4 md:border-r"
      >{{ itemIndex }}</span
    >
    <span class="text-sm px-4 py-4 flex-grow"
      ><i :class="cssClass"></i>{{ itemTitle }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    cssClass: {
      default: "",
    },
    itemTitle: {
      default: "",
    },
    itemIndex: {
      default: 0,
    },
    containerClass: {
      default: "draggable",
    },
  },
  data() {
    return {
      containerCss:
        this.containerClass +
        " flex mb-1 bg-gray-100 block text-black hover:bg-black hover:text-white rounde-sm w-full",
    };
  },
};
</script>

<style scoped>
.draggable {
  position: relative;
  cursor: move;
}
</style>
