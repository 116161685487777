<template>
   <div class="pretty p-bigger p-default">
        <input type="checkbox" :id="attributeName" v-model="checked">
        <div class="state">
            <label>{{ label }}</label>
        </div>
    </div>
</template>

<script>
    import {Component} from 'vue-instantsearch';

    export default {
        mixins: [Component],
        props: {
            attributeName: {
                type: String,
                required: true
            },
            label: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                checked: false
            }
        },
        watch: {
            checked(val) {
                this.apply()
            }
        },
        computed: {
            numericFilters() {
                let filters = this.searchStore.queryParameters.numericFilters|| []

                for (let k in filters) {

                    // Remove if filter is already applied.
                    if (filters[k].indexOf(this.attributeName + ' = 1') === 0) {
                        filters.splice(k, 1);
                    }
                }

                if (this.checked) {
                    filters.push(this.attributeName + ' = 1')
                }

                return filters
            }
        },
        methods: {
            apply() {
                this.searchStore.queryParameters = {
                    numericFilters: this.numericFilters
                }
            }
        }
    };

</script>
<style>

</style>