<template>
    <div class="container mx-auto">
        <header class="py-4">
            <div class="flex flex-wrap items-start justify-between w-full">
                <div class="flex md:items-center md:justify-start lg:mb-0 w-full md:w-1/3">
                    <h1 class="text-4xl mb-1">{{ pageTitle }}</h1>
                    <sortable-admin-listing-save :changeset="elementChanges" :items="items" :api-url="saveUrl" @adminListingSaving="onAdminListingSaving($event)"></sortable-admin-listing-save>
                </div>
            </div>
            <p class="mb-4 md:mb-0" v-if="backUrl !== ''">
                <a class="mt-2 no-underline text-grey-dark" :href="backUrl">
                    <i class="fas fa-arrow-left"></i> Back
                </a>
            </p>
        </header>

        <div class="w-full">
            <draggable :list="items" :disabled="!enabled" ghost-class="ghost" draggable=".draggable" @change="onOrderingChanged">
                <sortable-admin-listing-item v-for="(item, index) in items" :key="item.id" :item-index='index+1' :item-title="item.title" :css-class='item.cssClass' :container-class="'draggable'"></sortable-admin-listing-item>
            </draggable>
        </div>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import SortableAdminListingSave from "./SortableAdminListingSave";
import SortableAdminListingItem from "./SortableAdminListingItem";

export default {
    components: {
        draggable,
        SortableAdminListingSave,
        SortableAdminListingItem
    },
    props: {
        'sortableItems': {
            'type': Array,
            'default': function() {
                return [];
            }
        },
        'pageTitle': {
            'type': String,
            'default': ''
        },
        'saveUrl': {
            'type': String,
            'default': ''
        },
        'backUrl': {
            'type': String,
            'default': ''
        }
    },
    data: function() {
        return {
            items: this.sortableItems,
            enabled: true,
            elementChanges: []
        };
    },
    methods: {
        onAdminListingSaving: function(isSaving) {
            this.enabled = !isSaving;

            if (this.enabled) {
                console.log('Emptying changeset...')
                this.elementChanges = [];
            }
        },
        onOrderingChanged: function(evt) {
            if (evt.moved) {
                if (this.elementChanges.indexOf(evt.moved.element.id) === -1) {
                    this.elementChanges.push(evt.moved.element.id);
                }
                if (this.elementChanges.indexOf(this.items[evt.moved.oldIndex].id) === -1) {
                    this.elementChanges.push(this.items[evt.moved.oldIndex].id);
                }
            }
        }
    }
}
</script>

<style scoped>
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
</style>