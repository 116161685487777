<template>
  <section>
    <ul
      class="lg:flex lg:flex-wrap lg:items-end list-none lg:mr-8 text-xl w-full"
      v-click-outside="outside"
    >
      <li
        v-for="tab in 4"
        v-on:click="updateSelected(tab)"
        class="font-black xl:mx-6 cursor-pointer my-2 md:my-0"
      >
        <a
          href="/articles"
          class="
            flex
            justify-between
            lg:inline-block
            no-underline
            lg:px-2
            xl:px-4
            py-2
            text-white
            hover:text-white
          "
          v-if="tab == 1"
        >
          {{ my_tabs[tab - 1] }}</a
        >

        <a
          v-else
          v-bind:class="[
            selectedTab == tab
              ? 'md:bg-smoke-65 text-white hover:text-white rounded-t'
              : 'text-white hover:text-white',
          ]"
          class="
            flex
            justify-between
            lg:inline-block
            no-underline
            lg:px-2
            xl:px-4
            py-2
          "
        >
          {{ my_tabs[tab - 1] }}
          <i
            v-if="selectedTab == tab"
            class="fas fa-caret-down"
            v-bind:style="{ transform: 'rotate(180deg)' }"
          ></i>
          <i v-else class="fas fa-caret-down"></i>
        </a>

        <div class="md:hidden" v-if="tab == 2">
          <div class="" v-show="selectedTab == 2">
            <slot name="tab1"></slot>
          </div>
        </div>

        <div class="md:hidden" v-if="tab == 3">
          <div class="" v-show="selectedTab == 3">
            <slot name="tab2"></slot>
          </div>
        </div>

        <div class="md:hidden" v-if="tab == 4">
          <div class="" v-show="selectedTab == 4">
            <slot name="tab3"></slot>
          </div>
        </div>
      </li>
    </ul>

    <div class="hidden md:block w-full absolute left-0 z-50">
      <div class="" v-show="selectedTab == 2">
        <slot name="tab1"></slot>
      </div>

      <div class="" v-show="selectedTab == 3">
        <slot name="tab2"></slot>
      </div>

      <div class="" v-show="selectedTab == 4">
        <slot name="tab3"></slot>
      </div>
    </div>
  </section>
</template>

<script>
import { EventBus } from "./event-bus.js";
export default {
  data() {
    return {
      selectedTab: 0,
      my_tabs: [],
      tab: 0,
    };
  },
  props: ["tab_names", "total_tabs"],
  created() {
    this.tabs = this.$children;
    // split into an array
    this.my_tabs = this.tab_names.split(",");
  },
  methods: {
    showHideNav() {
      this.EventBus.$on("nav-clicked", this.visibleNav);
    },
    updateSelected: function (tab) {
      // check to see if they clicked on the same tab again and close it
      if (this.selectedTab == tab) {
        this.selectedTab = 0;
      } else {
        // set the tab
        this.selectedTab = tab;
      }
    },
    outside: function (e) {
      this.selectedTab = 0;
      console.log("clicked outside!");
    },
  },
  // make a custom directive to detect if user has clicked outside of nav section
  directives: {
    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;
        // add Event Listeners
        document.addEventListener("click", handler);
      },
      unbind: function (el, binding) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },
  },
};
</script>