<template>
  <section class="relative mb-24">
    <button class="p-3 rounded text-black" v-on:click="toggleMenu">
      <i class="fas fa-ellipsis-v"></i>
    </button>
    <div
      class="
        rounded
        shadow-md
        bg-white
        mt-2
        absolute
        mt-8
        top-0
        right-0
        min-w-full
      "
      v-show="menu"
    >
      <ul class="list-none">
        <slot> </slot>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      menu: false,
    };
  },
  mounted() {},
  methods: {
    toggleMenu: function (event) {
      // close menu if open already
      if (this.menu == true) {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },
  },
};
</script>

<style scoped>
</style>
